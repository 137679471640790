import Head from "next/head";
import { RequireLogin } from "../../components/RequireLogin";
import { Sections } from "../../features/Sections";

const SectionsPage = () => {
  return (
    <RequireLogin>
      <Head>
        <title>校舎一覧</title>
      </Head>
      <Sections />
    </RequireLogin>
  );
};

export default SectionsPage;

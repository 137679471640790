import Head from "next/head";
import { Header } from "./../Layout/Header";
import { Footer } from "./../Layout/Footer";
import styles from "./styles.module.scss";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<Record<string, unknown>>;
export const WhiteLayout = ({ children }: Props) => {
  return (
    <>
      <Head>
        <meta
          content="width=device-width,initial-scale=1.0,user-scalable=no"
          name="viewport"
        />
      </Head>
      <Header borderBottom />
      <div className={styles.Content}>{children}</div>
      <Footer />
    </>
  );
};

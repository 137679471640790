import * as React from "react";
import styles from "./styles.module.scss";

interface Props {
  loading: boolean;
}

const Loader = (props: Props) => {
  if (props.loading) {
    return (
      <div className={styles.root}>
        <img src="/assets/images/loader.svg" className={styles.image} />
      </div>
    );
  } else {
    return null;
  }
};

export default Loader;

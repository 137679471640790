import styles from "./styles.module.scss";
import { ErrorView } from "../../components/ErrorView";
import Loader from "../../components/Loader";
import { useSectionsApi } from "../../hooks/api/useSectionApi";
import { Section } from "../../domains/Section";
import { Flex } from "../../components/Flex";
import { Attention, School } from "../../components/Icon";
import Link from "next/link";
import { useRouter } from "next/router";
import { WhiteLayout } from "../../components/WhiteLayout";

export const Sections = () => {
  const { sections, isLoading, error } = useSectionsApi();
  const router = useRouter();

  // 所属校舎が一つだけの場合はその校舎の画面に遷移する
  if (!isLoading && sections.length === 1) {
    router.push(`/sections/${sections[0].id}`);
    return null;
  }

  return (
    <WhiteLayout>
      {isLoading ? null : <SectionList sections={sections} />}
      <ErrorView error={error} />
      <Loader loading={isLoading} />
    </WhiteLayout>
  );
};

const SectionList = ({ sections }: { sections: Section[] }) => (
  <ul className={styles.listbox}>
    {sections.length > 0 ? (
      sections.map((section) => (
        <SectionItem key={`section-${section.id}`} section={section} />
      ))
    ) : (
      <EmptyItem />
    )}
  </ul>
);

const EmptyItem = () => (
  <li className={styles.listitem}>
    <Flex alignItems="center">
      <Attention colorType="color-gray-darken-2" />
      <span className={styles.listitem__empty}>
        所属している校舎がありません。
      </span>
    </Flex>
  </li>
);

const SectionItem = (props: { section: Section }) => (
  <li className={styles.listitem}>
    <Link href={`/sections/${props.section.id}`}>
      <a>
        <Flex alignItems="center">
          <School colorType="color-primary" size="lg" />
          <span className={styles.listitem__name}>{props.section.name}</span>
        </Flex>
      </a>
    </Link>
  </li>
);

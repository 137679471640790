import { PropsWithChildren, useEffect, useRef } from "react";
import { useAuthentication } from "../AuthenticationProvider/useAuthentication";
import UnauthorizedErrorView from "../../pages/401";
import Loader from "../Loader";
import { useRouter } from "next/router";
import { AuthenticationStatus } from "../AuthenticationProvider";

type Props = PropsWithChildren<Record<string, unknown>>;

export const RequireLogin = ({ children }: Props) => {
  const { status, isLoaded } = useAuthentication();
  const previousStatusRef = useRef<AuthenticationStatus | null>(null);
  const router = useRouter();

  // authenticated => unauthenticated の時は UnauthorizedErrorView を表示させずにsign_inへ遷移する
  useEffect(() => {
    if (
      previousStatusRef.current === "authenticated" &&
      status === "unauthenticated"
    ) {
      previousStatusRef.current = null;
      router.replace("/sign_in", undefined, {
        shallow: true,
      });
    }
    previousStatusRef.current = status;
  }, [status]);
  if (!isLoaded) {
    return <Loader loading />;
  }

  if (
    previousStatusRef.current !== status &&
    previousStatusRef.current !== "authenticated" &&
    status === "unauthenticated"
  ) {
    return <UnauthorizedErrorView />;
  }

  return <>{children}</>;
};

import ApiClient, {
  NotFoundError,
  UnauthorizedError,
  UnexpectedError,
} from "../../helpers/api";
import { useQuery } from "react-query";
import { Section, SectionWithSetting } from "../../domains/Section";
import { HTTPError } from "../../helpers/api";

type UseSectionsApi = {
  sections: Section[];
  isLoading: boolean;
  error: HTTPError | null;
};
export const useSectionsApi = (): UseSectionsApi => {
  const path = `/api/contents/sections`;
  const result = useQuery<Section[], HTTPError>(
    path,
    async () => {
      const response = await ApiClient.get(path);
      if (response.ok) {
        const json = await response.json();
        return json.sections as Section[];
      }
      switch (response.status) {
        case 401:
          throw new UnauthorizedError();
        case 404:
          throw new NotFoundError();
        default:
          throw new UnexpectedError();
      }
    },
    {
      retry: false,
    },
  );
  return {
    sections: result.data ? result.data : [],
    isLoading: result.isLoading,
    error: result.error,
  };
};

type UseSectionApi = {
  data?: SectionWithSetting;
  isLoading: boolean;
  error: HTTPError | null;
};
export const useSectionApi = ({
  sectionId,
}: {
  sectionId: string;
}): UseSectionApi => {
  const path = `/api/contents/sections/${sectionId}`;
  const result = useQuery<SectionWithSetting, HTTPError>(
    path,
    async () => {
      const response = await ApiClient.get(path);
      if (response.ok) {
        const json = await response.json();
        return json.section as SectionWithSetting;
      }
      switch (response.status) {
        case 401:
          throw new UnauthorizedError();
        case 404:
          throw new NotFoundError();
        default:
          throw new UnexpectedError();
      }
    },
    {
      retry: false,
    },
  );
  return {
    data: result.data,
    isLoading: result.isLoading,
    error: result.error,
  };
};
